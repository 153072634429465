import {
  useCallback, useContext, useEffect, useState,
} from "react";

import { useMutation } from "@apollo/client";
import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { SessionType } from "@/autoGeneratedGlobalTypes";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { parseErrors } from "@/utils/globalTypesUtils";

import {
  createSession2 as createSession2Type,
  createSession2Variables,
} from "./graphql/__generated__/createSession2";
import { CREATE_SESSION2 } from "./graphql/CREATE_SESSION2";
import { UrlParametersObject } from "./types";

/**
 * Creates session if user is logged in or shows authorisation modal otherwise
 * @returns
 */
const useCreateSession = ({
  sessionType = SessionType.VIDEO_CALL,
  rewriteUrl = false,
  successCallback = (sessionID: number) => { },
  failCallback = (errorCodeList: number[]) => { },
}) => {
  const location = useLocation();
  const { isUserLoggedIn } = useContext(UserContext);
  const { setAlertStatus, setIsAlertShown } = useContext(GlobalContext);

  const [createSessionMutation, {
    data,
    error,
    loading,
  }] = useMutation<createSession2Type, createSession2Variables>(
    CREATE_SESSION2,
    {
      errorPolicy: "all", // todo: make general behaviour for network errors
    },
  );
  const [expertId, setExpertId] = useState<number | undefined>();
  const [urlParameters, setUrlParameters] = useState<UrlParametersObject>();

  const createSession = useCallback((id: number, sessionUrlParameters?: UrlParametersObject) => {
    if (!isUserLoggedIn) {
      setAuthParamToURL(location, FormTypeEnum.Login);
    }

    if (!id) {
      console.log("Specify expertId");
    } else {
      setExpertId(id);
      setUrlParameters(sessionUrlParameters);
      createSessionMutation({
        variables: {
          expertID: id,
          type: sessionType,
        },
      });
    }
  }, [
    createSessionMutation,
    isUserLoggedIn,
    location,
    sessionType,
  ]);

  useEffect(() => {
    if (data && !error && !loading) {
      const sessionID = data?.createSession2.sessionID;
      const urlParametersString = urlParameters
        ? `&${Object.keys(urlParameters)
          .map((k) =>
            `${k}=${urlParameters[k]}`)
          .join(",")}`
        : "";

      // todo: probably delete both redirect and separate session page
      if (sessionID) {
        if (sessionType === SessionType.VIDEO_CALL) {
          navigate(`/chat/?sessionId=${sessionID}${urlParametersString}`, { replace: rewriteUrl });
        } else if (sessionType === SessionType.CHAT) {
          navigate(`/textChat/?sessionId=${sessionID}${urlParametersString}`, { replace: rewriteUrl });
        } else {
          successCallback(sessionID);
        }
      }
    } else if (error) {
      const errors = parseErrors(error);
      errors.forEach((errorCode: number) => {
        switch (errorCode) {
          case 7:
            // debugger;
            console.log("redirect");
            navigate(`/appointment/?expert_id=${expertId}&session_type=${sessionType}`, { replace: true });
            break;
          case 6:
            setAlertStatus("unavailable");
            setIsAlertShown(true);
            break;
          default:
            console.log("unknown error");
        }
      });
      failCallback(errors);
    }
  }, [
    data,
    error,
    loading,
    expertId,
    rewriteUrl,
    sessionType,
    failCallback,
    setAlertStatus,
    setIsAlertShown,
    successCallback,
    urlParameters,
  ]);

  return [createSession];
};

export default useCreateSession;
